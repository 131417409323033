import styles from './DatePicker.module.scss';
import type { FunnelTheme } from '@quno/patient-journey/types/FunnelTheme';
import type { DateTimePickerElement, Slot } from 'cronofy-elements';
import type { BookingAvalabilityOptionsSuccess } from '@quno/patient-journey/types/BookingAvalabilityOptions';
import type { TranslationFunction } from '@quno/patient-journey/src/hooks/useTranslation';

export const createDatePickerElement = async (
  target: string,
  options: BookingAvalabilityOptionsSuccess,
  locale: string,
  onSlotSelected: (slot: Slot) => Promise<void>,
  onDateSelected: (date: string) => Promise<void>,
  t: TranslationFunction,
  theme?: FunnelTheme,
): Promise<DateTimePickerElement> => {
  const CronofyElements = await import('cronofy-elements');

  const {
    element_token,
    information: { timeZone },
    availability_query,
    config,
  } = options;

  return CronofyElements.DateTimePicker({
    element_token,
    tzid: timeZone,
    availability_query,
    data_center: 'de',
    locale,
    target_id: target,
    config,
    translations: {
      en: {
        date_time_picker: {
          select_time_slot: t('calendar.confirm'),
        },
      },
      de: {
        date_time_picker: {
          select_time_slot: t('calendar.confirm'),
        },
      },
    },

    styles: {
      prefix: styles.calendar,
      colors: {
        button:
          theme?.optionDefaultBackgroundColor || 'var(--theme-primary-color)',
        buttonText:
          theme?.optionDefaultTextColor ||
          theme?.generalBackgroundColor ||
          'white',
        buttonConfirm: 'var(--theme-primary-color)',
        buttonHover:
          theme?.optionHoverBackgroundColor ||
          'var(--theme-background-color-dark)',
        buttonHoverText: theme?.optionHoverTextColor || 'white',
        buttonActive:
          theme?.optionActiveBackgroundColor ||
          'var(--theme-background-color-dark)',
        buttonActiveText: theme?.optionActiveTextColor || 'white',
      },
    },

    async callback({ notification }) {
      if (notification.type === 'date_selected') {
        await onDateSelected(notification.date);
      }

      if (notification.type === 'slot_selected') {
        await onSlotSelected(notification.slot);
      }
    },
  });
};
